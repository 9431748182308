import React, { useCallback, useEffect, useState } from "react";
import { Button, Spinner, Modal, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import moment from "moment";

import { DatePicker } from "antd";
import { FaCross, FaExclamationTriangle } from "react-icons/fa";
import { getTagsAction } from "../../../redux/actions";
import { PATH } from "../../config";

export const ClinicsPicker = ({ clinicsData, setClinicsData, columnSize }) => {
  const clinics = useSelector((state) => state.clinicsReducer);
  const loggingActivityReducer = useSelector(
    (state) => state.loggingActivityReducer
  );
  let dispatch = useDispatch();
  useEffect(() => {
    document.title = "DHIS2 Upload | Caafimaad Plus";

    dispatch(getTagsAction(true));

    // eslint-disable-next-line
  }, []);
  const { RangePicker } = DatePicker;
  let now = new Date();
  let start = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  );
  let end = moment(start)
    .add(1, "days")
    .subtract(1, "seconds");
  const [clinic, setClinic] = useState([]);
  const locationReducer = useSelector(
    (state) => state.locationReducer?.allLocationsList
  );
  const location = useLocation();

  const [filteredRegions, setfilteredRegions] = useState(null);
  const [filteredDistricts, setfilteredDistricts] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  const [selectedregion, setselectedregion] = useState(null);
  const [districts, setdistricts] = useState(null);
  const [valueByLocation, setvalueByLocation] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [onRadioButtonChange, setOnRadioButtonChange] = useState("location");

  useEffect(() => {
    var filteredClinics = clinics.clinicsList;
    if (filteredClinics === null) return;
    if (selectedState !== null) {
      filteredClinics = filteredClinics.filter(
        (x) => x.state != null && x.state.trim() == selectedState
      );
    } else {
      return;
    }

    if (selectedregion !== null) {
      filteredClinics = filteredClinics.filter(
        (x) => x.region != null && x.region.trim() == selectedregion
      );
    }
    if (districts !== null) {
      filteredClinics = filteredClinics.filter(
        (x) => x.city != null && x.city.trim() == districts
      );
    }

    setClinicsData({
      clinics: filteredClinics.map((item) => {
        return { value: item.id, label: item.name };
      }),
      label:
        filteredClinics !== null && filteredClinics.length == 1
          ? filteredClinics[0].name
          : districts !== null
            ? districts
            : selectedregion !== null
              ? selectedregion
              : selectedState !== null
                ? selectedState
                : "",
    });

    // let data = clinics.clinicsList.filter(
    //     (item) => item.locationName === selectedState

    // ).map((item) => { return { value: item.id, label: item.name } })
    // let filters = {
    //     State: selectedState ? selectedState : '',
    //     Region: selectedregion ? selectedregion : "",
    //     City: districts ? districts : "",
    // }
    // dispatch(getAllClinicsList(filters))
    //eslint-disable-next-line
  }, [selectedregion, selectedState, districts]);

  useEffect(() => {
    let data = clinics.clinicsList.map((item) => {
      return { value: item.id, label: item.name };
    });
    setClinicsData({
      label: "",
      clinics: [],
    });
    setvalueByLocation(data);
    onValuesChanged();
  }, [clinics.clinicsList]);

  const [isCustom, setIsCustom] = useState(false);
  const [filterOpt, setFilterOpt] = useState({
    value: "30",
    label: "Last 30 days",
  });
  const intialState = {
    filters: {
      FromDate: moment(start)
        .subtract(29, "days")
        .format("YYYY-MM-DD"),
      ToDate: moment(end).format("YYYY-MM-DD"),
    },
    values: {
      ClinicIds: [],
    },
  };
  const [logActAnalyticFilter, setlogActAnalyticFilter] = useState(intialState);

  function changeOption(data) {
    setFilterOpt(data.value);

    if (data.value === "today") {
      setIsCustom(false);
      setlogActAnalyticFilter({
        ...logActAnalyticFilter,
        filters: {
          ...logActAnalyticFilter.filters,
          FromDate: moment(start).format("YYYY-MM-DD"),
          ToDate: moment(end).format("YYYY-MM-DD"),
        },
      });
    } else if (data.value === "yesterday") {
      setIsCustom(false);
      setlogActAnalyticFilter({
        ...logActAnalyticFilter,
        filters: {
          ...logActAnalyticFilter.filters,
          FromDate: moment(start)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          ToDate: moment(end)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
        },
      });
    } else if (data.value === "7") {
      setIsCustom(false);
      setlogActAnalyticFilter({
        ...logActAnalyticFilter,
        filters: {
          ...logActAnalyticFilter.filters,
          FromDate: moment(start)
            .subtract(6, "days")
            .format("YYYY-MM-DD"),
          ToDate: moment(end).format("YYYY-MM-DD"),
        },
      });
    } else if (data.value === "30") {
      setIsCustom(false);
      setlogActAnalyticFilter({
        ...logActAnalyticFilter,
        filters: {
          ...logActAnalyticFilter.filters,
          FromDate: moment(start)
            .subtract(29, "days")
            .format("YYYY-MM-DD"),
          ToDate: moment(end).format("YYYY-MM-DD"),
        },
      });
    } else if (data.value === "90") {
      setIsCustom(false);
      setlogActAnalyticFilter({
        ...logActAnalyticFilter,
        filters: {
          ...logActAnalyticFilter.filters,
          FromDate: moment(start)
            .subtract(89, "days")
            .format("YYYY-MM-DD"),
          ToDate: moment(end).format("YYYY-MM-DD"),
        },
      });
    } else if (data.value === "custom") {
      setIsCustom(true);
    }
  }
  function onchangeFuhnc(e, b) {
    setlogActAnalyticFilter({
      ...logActAnalyticFilter,
      filters: {
        ...logActAnalyticFilter.filters,
        FromDate: b[0],
        ToDate: b[1],
      },
    });
  }
  const resetForm = () => setlogActAnalyticFilter(intialState);
  const onSuccessFunc = () => {
    resetForm();
  };
  const fileName = `${logActAnalyticFilter.filters?.FromDate}_TO_${logActAnalyticFilter.filters?.ToDate}.xlsx`;
  function onExportAnalyticsData() {
    let finalIds = clinic?.map((x) => x.value);

    let filters = {
      FromDate: logActAnalyticFilter.filters?.FromDate,
      ToDate: logActAnalyticFilter.filters?.ToDate,
      ClinicIds:
        selectedState !== null
          ? valueByLocation?.map((x) => x.value).toString()
          : clinic?.map((x) => x.value).toString(),
      Name:
        valueByLocation.length == 1
          ? valueByLocation[0].label
          : districts !== null
            ? districts
            : selectedregion !== null
              ? selectedregion
              : selectedState !== null
                ? selectedState
                : "",
    };

  }
  const changeState = (value) => {
    let data = clinics.clinicsList.map((item) => {
      return { value: item.id, label: item.name };
    });
    setvalueByLocation(data);
    if (value !== null) {
      setselectedState(value.value);
      setdistricts(null);
      setfilteredRegions(null);
      setselectedregion(null);
      setfilteredDistricts(null);
      let data = locationReducer?.states?.find(
        (x) => x.locationName === value.value
      )?.id;
      setfilteredRegions(
        locationReducer?.regions?.filter((s) => s.stateId === data)
      );
    }
  };
  const changeTag = (value) => {
    // setSelectedOption(value?.value);

    let dataFiltered = [];
    for (let clinicId = 0; clinicId < clinics.clinicsList.length; clinicId++) {
      // setselectedState(value?.value);
      if (
        clinics?.clinicsList[clinicId]?.tags?.filter(
          (tag) => tag.tag === value?.label
        )?.length > 0
      ) {
        for (
          let finalTag = 0;
          finalTag < clinics?.clinicsList[clinicId]?.tags.length;
          finalTag++
        ) {
          if (
            clinics?.clinicsList[clinicId]?.tags?.length > 0 &&
            clinics?.clinicsList[clinicId]?.tags[finalTag].tag === value?.label
          ) {
            dataFiltered.push(clinics?.clinicsList[clinicId]);
            // setselectedState(value?.value)
          }
        }
      }
    }
    if (value !== null) {

      setClinicsData({
        ...clinicsData,
        clinics: dataFiltered
          ?.filter((filteredItem) => filteredItem !== undefined)
          ?.map((item) => {
            return { value: item?.id, label: item?.name };
          }),
        label: value?.label
      });
    }
  };

  const onValuesChanged = () => {
    // onChange(selectedState, districts, selectedregion, valueByLocation,
    //     (valueByLocation!==null&&valueByLocation.length==1)?valueByLocation[0].label:districts!==null?districts:
    //     selectedregion!==null?selectedregion:selectedState!==null?selectedState:""(valueByLocation!==null&&valueByLocation.length==1)?valueByLocation[0].label:districts!==null?districts:
    //     selectedregion!==null?selectedregion:selectedState!==null?selectedState:"")
  };

  const changeRegion = (value) => {
    let data = clinics?.clinicsList?.map((item) => {
      return { value: item.id, label: item.name };
    });
    setvalueByLocation(data);
    if (value !== null) {
      setselectedregion(value.value);
      setdistricts(null);

      let data = locationReducer?.regions?.find(
        (x) => x.locationName === value.value
      )?.id;
      setfilteredDistricts(
        locationReducer?.districts?.filter((s) => s.regoinId === data)
      );
    } else {
      setfilteredRegions(null);
      setfilteredDistricts(null);
    }
  };
  const changeDistrict = (value) => {
    let data = clinics?.clinicsList?.map((item) => {
      return { value: item.id, label: item.name };
    });
    setvalueByLocation(data);
    if (value !== null) {
      setdistricts(value.value);
    } else {
      setfilteredRegions(null);
      setfilteredDistricts(null);
    }
  };

  const onClinicChange = (a, b) => {
    ;
    setClinic(a);
    if (b?.action === "remove-value") {
      let dataFiltered = clinicsData.clinics?.filter(
        (x) => x !== b.removedValue
      );
      setvalueByLocation(dataFiltered);
      setClinicsData({
        ...clinicsData,
        clinics: dataFiltered,
      });
    }
    if (b?.action === "select-option") {
      valueByLocation.push(b?.option);
      setClinicsData({
        ...clinicsData,
        label: b.option.label,
        clinics:
          setClinicsData.clinics !== undefined &&
            setClinicsData.clinics !== null
            ? [...setClinicsData.clinics, b.option]
            : [b.option],
      });
    }
    if (b?.action === "clear") {
      setvalueByLocation([]);
      setClinicsData({
        label: "",
        clinics: [],
      });
    }
  };
  const handleRadioChange = (event) => {
    setClinicsData({
      label: "",
      clinics: [],
    });
    const selectedValue = event.target.value;
    ;
    setOnRadioButtonChange(selectedValue);
  };
  return (
    <div className="row d-flex">
      <div className="mx-4 mb-5">
        <input
          type="radio"
          className="mr-2"
          value="location"
          checked={onRadioButtonChange === "location"}
          onChange={handleRadioChange}
        />
        <label style={{ fontSize: "13px", color: "#848080" }} className="mr-2">
          Filter by State
        </label>
        <input
          type="radio"
          className="ml-4 "
          value="label"
          checked={onRadioButtonChange === "label"}
          onChange={handleRadioChange}
        />
        <label className="ml-2" style={{ fontSize: "13px", color: "#848080" }}>
          Filter by Label
        </label>
      </div>
      {onRadioButtonChange === "label" && (
        <>
          <Col lg={`${columnSize ? columnSize : "12"}`} className="mb-3">
            <Select
              options={(clinics?.getTagsList || []).map((item) => {
                return { value: item.tag, label: item.tag };
              })}
              className="d-inline-block w-100"
              placeholder="Select tag"
              onChange={(value) => changeTag(value)}
              name="state"

            />
          </Col>
          <div className={`${columnSize ? "col-md-8 mb-3" : "col-md-12 mb-3"}`}>
            <Select
              className="d-inline-block w-100"
              placeholder="Clinics"
              options={(clinics?.getTagsList?.[0]?.clinics || []).map(
                (item) => {
                  return { value: item.id, label: item.name };
                }
              )}
              onChange={(a, b) => onClinicChange(a, b)}
              name="ClinicIds"
              value={clinicsData.clinics ? clinicsData.clinics : ""}

              isClearable
              isMulti={
                location.pathname.includes(PATH.DHIS_REQUESTS) ? false : true
              }
              isLoading={clinics?.getClinicListLoading}
            />
          </div>
        </>
      )}
      {onRadioButtonChange === "location" && (
        <>
          <Col lg={`${columnSize ? columnSize : "12"}`} className="mb-3">
            <Select
              options={(locationReducer?.states || []).map((item) => {
                return { value: item.locationName, label: item.locationName };
              })}
              className="d-inline-block w-100"
              placeholder="Select state"
              onChange={(value) => changeState(value)}
              name="state"
              value={
                selectedState
                  ? { value: selectedState, label: selectedState }
                  : ""
              }
            />
          </Col>
          <Col lg={`${columnSize ? columnSize : "12"}`} className="mb-3">
            <Select
              options={(filteredRegions || []).map((item) => {
                return { value: item.locationName, label: item.locationName };
              })}
              className="d-inline-block w-100"
              placeholder="Select region"
              onChange={(value) => changeRegion(value)}
              name="region"
              value={
                selectedregion
                  ? { value: selectedregion, label: selectedregion }
                  : ""
              }
            // isDisabled={selectedState === null}
            />
          </Col>
          <Col lg={`${columnSize ? columnSize : "12"}`} className="mb-3">
            <Select
              options={(filteredDistricts || []).map((item) => {
                return { value: item.locationName, label: item.locationName };
              })}
              className="d-inline-block w-100"
              placeholder="Select district"
              onChange={(value) => changeDistrict(value)}
              name="district"
              value={districts ? { value: districts, label: districts } : ""}
            // isDisabled={selectedregion === null}
            />
          </Col>
          <div className={`${columnSize ? "col-md-8 mb-3" : "col-md-12 mb-3"}`}>
            <Select
              className="d-inline-block w-100"
              placeholder="Clinics"
              options={(clinics.clinicsList || []).map((item) => {
                return { value: item.id, label: item.name };
              })}
              onChange={(a, b) => onClinicChange(a, b)}
              name="ClinicIds"
              value={clinicsData.clinics ? clinicsData.clinics : ""}
              // defaultValue={valueByLocation || ""}
              isClearable
              isMulti={
                location.pathname.includes(PATH.DHIS_REQUESTS) ? false : true
              }
              isLoading={clinics?.getClinicListLoading}
            />
          </div>
        </>
      )}

      <div className="col-md-12">
        {loggingActivityReducer.exportAnalyticsFailure ||
          loggingActivityReducer.exportAnalyticsError ? (
          <>
            <FaExclamationTriangle
              size="16"
              className="text-danger"
              title={loggingActivityReducer.exportAnalyticsError}
            />
            <small className="ml-1 text-danger errorText">
              {loggingActivityReducer.exportAnalyticsError}
            </small>
          </>
        ) : null}
      </div>
    </div>
  );
};
